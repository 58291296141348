<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Exercice Préparatoire pour l'Expérience 5: Question 1</h2>

      <p class="mb-n3">
        a) La méthode idéelle pour déterminer la vitesse de la réaction entre le peroxyde
        d’hydrogène et la catalase ou l’iodure de potassium dans nos laboratoires est:
      </p>

      <v-radio-group v-model="inputs.idealMethod" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsIdealMethod"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        b) Dans une réaction qui prend environ 10 min pour devenir complète, à quel temps faut-il
        mesurer les vitesses pour être capable de bien appliquer la méthode des vitesses initiale?
      </p>

      <v-radio-group v-model="inputs.timeFrame" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsTimeFrame"
          :key="'pt-2-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'UOttawaNewPrelab5Q1Fr',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        idealMethod: null,
        timeFrame: null,
      },
      optionsIdealMethod: [
        {
          text: 'De répéter l’expérience plusieurs fois et de changer des variables',
          value: 'changeDifferentVars',
        },
        {
          text: 'De garder constant la concentration d’un des réactifs',
          value: 'oneReactConstant',
        },
        {
          text: 'De garder la concentration initiale de la catalase/le KI constante, de varier la concentration de H2O2 et d’appliquer la méthode des vitesses initiales',
          value: 'varyH2O2',
        },
        {
          text: 'De garder la concentration initiale de H2O2 constante, de varier la concentration de la catalase/le KI et d’appliquer la méthode des vitesses initiales',
          value: 'varyCatalyst',
        },
        {
          text: 'De garder la concentration de la catalase/le KI constante et en grande excès et d’appliquer la loi de vitesse intégrée.',
          value: 'catalystLargeXS',
        },
        {
          text: 'De garder la concentration de H2O2 constante et en grande excès et d’appliquer la loi de vitesse intégrée.',
          value: 'H2O2LargeXS',
        },
      ],
      optionsTimeFrame: [
        {text: '0 à 30 s', value: '0to30'},
        {text: '0 à 60 s', value: '0to60'},
        {text: 'Pendant les 10 min', value: 'fullTime'},
        {text: '60 à 120 s', value: '60to120'},
        {text: 'Dans la dernière 1 min', value: 'lastMin'},
      ],
    };
  },
};
</script>
<style scoped></style>
